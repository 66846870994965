<template>
  <g :fill="color" fill-rule="nonzero">
    <circle cx="7.5" cy="7.5" r="7.5" />
    <path
      fill="#FFF"
      d="M6.637 5.84a.835.835 0 0 0 0-1.18l-.886-.886a.835.835 0 0 0-1.18 0l-.486.486a1.25 1.25 0 0 0-.158 1.576 19.412 19.412 0 0 0 5.427 5.426 1.252 1.252 0 0 0 1.575-.157l.486-.486a.835.835 0 0 0 0-1.181l-.885-.886a.835.835 0 0 0-1.181 0l-.295.296a19.803 19.803 0 0 1-2.712-2.713l.295-.295z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
